<template>
  <div>
    <ul class="nav nav-tabs justify-content-begin">
      <li @click="changeTab('stores')" class="nav-item">
        <a class="nav-link" :class="tab == 'stores' ? 'active' : ''" aria-current="page">Cadenas</a>
      </li>
      <li @click="changeTab('branchs')" class="nav-item">
        <a class="nav-link" :class="tab == 'branchs' ? 'active' : ''">Sucursales</a>
      </li>
      <li @click="changeTab('products')" class="nav-item">
        <a class="nav-link" :class="tab == 'products' ? 'active' : ''">Productos</a>
      </li>
      <li @click="changeTab('collaborators')" class="nav-item">
        <a class="nav-link" :class="tab == 'collaborators' ? 'active' : ''">Colaboradores</a>
      </li>
      <li @click="changeTab('visits')" class="nav-item">
        <a class="nav-link" :class="tab == 'visits' ? 'active' : ''">Visitas</a>
      </li>
    </ul>
    <br />
    <div class="row">
    <div class="col-12" style="padding: 0px;">
      <select v-model="week" style="float: right; padding: 2px;" @change="loadWeekVisits()">
        <option value="8">Semana del 19/02/2024 - 25/02/2024</option>
        <option value="7">Semana del 12/02/2024 - 18/02/2024</option>
        <option value="6">Semana del 05/02/2024 - 11/02/2024</option>
      </select>
      Visitas de la Semana
      <br /> <br />
      </div>
      </div>
    <div class="row">
      <div class="col-2" v-for="week_day in week_days" :style="'border: 0px solid; padding: 10px; font-size: 10px; background-color: '+week_day.color">
        <span style="font-size: 12px; font-weight: bold;">{{week_day.name}}<hr /></span>
        <draggable
          class="list-group"
          tag="ul"
          v-model="visits_lists[week_day.id]"
          v-bind="dragOptions"
          @start="isDragging = true"
          @end="isDragging = false"
          @change="finish"
          @remove="remove($event, week_day.id)"
          @add="add($event, week_day.id)"
        >
          <transition-group type="transition" name="flip-list">
            <li
              class="list-group-item"
              v-for="element in visits_lists[week_day.id]"
              :key="element.id"
            >
              <i
                :class="
                  element.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'
                "
                @click="element.fixed = !element.fixed"
                aria-hidden="true"
              ></i>
              <CIcon name="cilCheckCircle" style="width: 20px; height: 20px; float: right; margin-top: 5px; color: green" v-if="element.status == 1 && element.is_validated == 1"/>
              <CIcon name="cilCheckCircle" style="width: 20px; height: 20px; float: right; margin-top: 5px; color: gray" v-if="element.status == 1 && element.is_validated == 0"/>
              <CIcon name="cilCircle" style="width: 20px; height: 20px; float: right; margin-top: 5px; color: gray" v-if="element.status == 0"/>
              <CIcon name="cilXCircle" style="width: 20px; height: 20px; float: right; margin-top: 5px; color: red" v-if="element.status == 1 && element.is_validated == -1"/>
              <b>{{ element.branch_name }}</b><br />
              {{ element.store_name }}
            </li>
          </transition-group>
        </draggable>
      </div>
    </div>
    <br />
  </div>
</template>
<script>

import store from '../../store'
import router from '../../router/index'
import ws from '../../services/admin';
import moment from 'moment';

import draggable from 'vuedraggable'

export default {
  name: 'Home',
  display: "Simple",
  order: 0,
  components: {
    draggable
  },
  data () {
    return {
      enabled: true,
      week: 7,
      contract_id: 274,
      week_days: [
        {id: 1, name: "Lunes", color: "#FBB9C5"},
        {id: 2, name: "Martes", color: "#FDD0B1"},
        {id: 3, name: "Miercoles", color: "#F9EFC7"},
        {id: 4, name: "Jueves", color: "#C3EDBF"},
        {id: 5, name: "Viernes", color: "#B8DFE6"},
        {id: 6, name: "Sábado", color: "#C5BBDE"},
      ],
      visits_lists: [],
      dragging: false,
      item: false
    }
  },
  mounted: async function() {
    this.loading();

    this.contract_id = await this.$route.params.id;

    let response = await ws.getVisits(this.contract_id, this.week); 

    if(response.type == "success"){
      this.visits_lists = response.data;
    }

    this.loaded();
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    async loadWeekVisits() {
      this.loading();

      let response = await ws.getVisits(this.contract_id, this.week); 

      if(response.type == "success"){
        this.visits_lists = response.data;
      }

      this.loaded();
    },
    finish(item) {
      if(item.added != undefined){
        this.item = item;
      }
    },
    add(event, day) {
      let _this = this;

      setTimeout(async function(){
        let visit_id = _this.item.added.element.id;
        
        _this.loading();

        let response = await ws.changeVisit(visit_id, day); 

        if(response.type == "success"){
          this.visits_lists = response.data;
        }

        _this.showToast(response.type, response.message);

        _this.loaded();
      }, 100);
    },
    remove(event, remove_day) {
      console.log(event);
      console.log(remove_day);
    },
    showToast(type, message){
        store.commit('toast', {color: type, message: message});
    },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
<style>
.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
  padding: 5px 10px 5px 15px !important;
  background: #eeeeee;
}

.list-group-item i {
  cursor: pointer;
}
</style>